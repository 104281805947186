function pluck(obj: ObjectOf<any>, ...keys: string[]) {
    return keys.reduce((acc, v) => {
        acc[v] = obj[v];
        return acc;
    }, {});
}

export function storeItemToJSON(storeItem: ObjectOf<any>) {
    const serializableProps = [];
    for (const prop in storeItem) {
        if (storeItem.hasOwnProperty(prop)) {
            const constructor =
                (storeItem[prop] && storeItem[prop].constructor.name) || "Undefined";
            if (["Store", "Services"].indexOf(constructor) === -1 && prop !== "root") {
                serializableProps.push(prop);
            }
        }
    }
    return pluck(storeItem, ...serializableProps);
}
