import { IApiResponse, HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreCursorParams } from "src/store/_generic/pagination-store-cursor";
import { IPaginatedCursorResponse } from "src/types/paginated-response";

export enum IOrderLineStatus {
    Reserved = "Reserved",
    Pending = "Pending",
    Hold = "Hold",
    Refunded = "Refunded",
    Cancelled = "Cancelled",
}

interface IDiscounts {
    duration: number;
    name: string;
}

export interface IStockRecord {
    basePriceInclTax: number;
    basePriceExclTax: number;
    currency: string;
    discount: number;
    discountType: string | null;
    hasInfiniteStock: boolean;
    id: number;
    numAllocated: number | null;
    numInStock: number | null;
    requiredPurchase: number;
    totalPriceExclTax: number;
    totalPriceInclTax: number;
}

export interface IOrderLine {
    id: number;
    comment: string;
    associatedOrderLine: number | null;
    discounts?: IDiscounts[];
    image: string;
    linePriceExclTax: number;
    linePriceInclTax: number;
    linePriceBeforeDiscountsExclTax: string;
    linePriceBeforeDiscountsInclTax: string;
    linkedOrderLines: number[];
    order: number;
    quantity: number;
    reservedUntil: string | null;
    status: IOrderLineStatus;
    stockrecord: IStockRecord;
    title: string;
    unitPriceInclTax: number;
    unitPriceExclTax: number;
}

export interface IOrderLineCompensationStatus {
    id: number;
    compensatable: boolean;
}

export class OrderLinesService extends Service {
    public async list(params: IPaginationStoreCursorParams) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "order-lines"], params);

        return this.client.get<
            | IApiResponse<IPaginatedCursorResponse<IOrderLine>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public listAllForInvoice = (params: IPaginationStoreCursorParams) =>
        this.createCursorListTraverser((p) => this.list(p))(params);

    public async retrieve(orderLineId: number) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "order-lines", orderLineId]);
        const hydrate = (orderLine: IOrderLine) => ({
            ...orderLine,
            linePriceInclTax: Number(orderLine.linePriceInclTax),
            linePriceExclTax: Number(orderLine.linePriceExclTax),
            unitPriceInclTax: Number(orderLine.unitPriceInclTax),
            unitPriceExclTax: Number(orderLine.unitPriceExclTax),
        });

        const response = await this.client.get<
            IApiResponse<IOrderLine, HttpStatus.Ok> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);

        if (response.status === HttpStatus.Ok) {
            return this.client.hydrateBody(response, hydrate);
        } else {
            throw new Error(`Cannot find orderLine with id ${orderLineId}`);
        }
    }

    public async retrieveCompensationStatus(orderLineID: number) {
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "order-lines",
            orderLineID,
            "compensation-status",
        ]);
        return this.client.get<
            | IApiResponse<IOrderLineCompensationStatus, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public async compensate(orderLineId: number, resource: { amount: string; comment: string }) {
        const body = { compensation_amount: resource.amount, comment: resource.comment };
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "order-lines",
            orderLineId,
            "compensate",
        ]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: IOrderLine;
                [HttpStatus.BadRequest]: ValidationError<IOrderLine>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, body);
    }
}
