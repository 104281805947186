import { LoadingStore, ILoadingStateKeys } from "src/store/global";

/**
 * Symbol for the decorator to receive the loader from the target class.
 *
 * Usage:
 * export class Foo {
 *      get [LOADER]() { return this.getTheStoreSomehow().utility.$loading; }
 *
 *      @loads("some-resource")
 *      public async loadSomeResource() {
 *          ...
 *      }
 * }
 */
export const LOADER = Symbol("LOADER");

export interface IHasLoader {
    [LOADER]: LoadingStore;
}

/**
 * Decorator factory that will flag all async loading operators as loading the
 * provided ILoadingStateKey.
 *
 * This requires the target object to look like IHasLoader (handle the symbol LOADER)
 *
 * Usage:
 * // Annotate with the resource key
 * @loads("resource/foo")
 * async myMethod() { ... }
 *
 * // Or if using a static symbol on a class, allow the class instance to
 * // be created before accessing the value
 * static readonly LOADING = Symbol("LOADING");
 * @loads(() => Form.LOADING)
 * async myOtherMethod() { ... }
 *
 * // And the decorator supports being called if the method returns a non-promise
 * // value.
 * @loads(() => Form.SAVES)
 * save() {
 *     if (canSave) {
 *         return false;
 *     }
 *     return this.saveAsync();
 * }
 */
export function loads(loadingStateKey: ILoadingStateKeys | (() => ILoadingStateKeys)) {
    return function (
        target: IHasLoader,
        memberName: string,
        propertyDescriptor: PropertyDescriptor
    ) {
        const method: any = target[memberName];
        propertyDescriptor.value = function loadsWrapper(this: IHasLoader, ...args: any[]) {
            const result = method.call(this, ...args);
            if (!(result instanceof Promise)) {
                return result;
            }
            const key = typeof loadingStateKey === "function" ? loadingStateKey() : loadingStateKey;
            return this[LOADER].around(key, () => result);
        };
    };
}
