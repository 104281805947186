import format from "date-fns/format";
import Debug from "debug";
import qs from "querystringify";

import { TargetApplication } from "src/lib/target-application";
import { throwError } from "src/lib/throw-error";

const debug = Debug("tmpl:config/environment");

function cfg(name: string, value: string | (() => string)): string {
    /* Do not throw or continue if running application as part of storybook
     * as it will cancel further imports of stories. Best would be if this code
     * is not called at all in Storybook */
    if (process.env.STORYBOOK) {
        return "";
    }
    const key = `REACT_APP_${name.toUpperCase()}`;
    const envValue = process.env[key];
    if (envValue !== undefined) {
        return envValue;
    }
    return typeof value === "function" ? value() : value;
}

const API_CORE_PROTOCOL = cfg("API_CORE_PROTOCOL", "http");
const API_CORE_HOSTNAME = cfg("API_CORE_HOSTNAME", "local.tmpl.nu");

export const APPLICATION = cfg("TARGET_APPLICATION", () =>
    throwError("REACT_APP_TARGET_APPLICATION must be set")
);

export const IDHUB_URL = cfg("IDHUB_URL", () => {
    if (APPLICATION === TargetApplication.IdHub) {
        return "";
    }
    throw new Error("IDHUB_URL must be set");
});

export const SELF_HOSTNAME = cfg("SELF_HOSTNAME", `${APPLICATION}.local`);

export const API_CORE_CLIENT_ID = cfg("API_CORE_CLIENT_ID", () => {
    if (APPLICATION === TargetApplication.IdHub) {
        return "";
    }
    throw new Error("CLIENT_ID must be set");
});

export const API_CORE_APPLICATION_IDENTIFIER = cfg("APPLICATION_IDENTIFIER", () => {
    if (APPLICATION === TargetApplication.IdHub) {
        return "";
    }
    throw new Error("APPLICATION_IDENTIFIER must be set");
});

export const API_CORE_PUBLIC_SUBDOMAIN = cfg("API_CORE_TENANT_SUBDOMAIN", "public");
export const API_CORE_DEFAULT_TENANT_SUBDOMAIN = cfg("API_CORE_DEFAULT_TENANT_SUBDOMAIN", "tmpl");
export const API_CORE_TENANT_NAME = cfg("API_CORE_TENANT_NAME", () => {
    const params = qs.parse(window.location.search);
    if ("wl" in params && params["wl"] != null) {
        const wl = Array.isArray(params["wl"]) ? params["wl"] : params["wl"];
        return wl.toString();
    }

    const index = window.location.hostname.indexOf(SELF_HOSTNAME);
    return index <= 0
        ? API_CORE_DEFAULT_TENANT_SUBDOMAIN
        : window.location.hostname.slice(0, index - 1);
});
export const FORCE_ONBOARDING_TENANTS = cfg(
    "FORCE_ONBOARDING_TENANTS",
    API_CORE_DEFAULT_TENANT_SUBDOMAIN
)
    .toLowerCase()
    .split(",");

export const API_CORE_PUBLIC_URL = cfg(
    "API_CORE_PUBLIC_URL",
    () => `${API_CORE_PROTOCOL}://${API_CORE_PUBLIC_SUBDOMAIN}.${API_CORE_HOSTNAME}`
);

export const SENTRY_DSN = cfg("SENTRY_DSN", "");
export const BUILD_TS = cfg("BUILD_TS", "-");
export const GIT_REV = cfg("GIT_REV", "-");

export const VERSION = ((version: string) => {
    return !version || version === "development"
        ? `dev-${APPLICATION}-${format(new Date(), "yyLL")}.${GIT_REV}`
        : version;
})(cfg("VERSION", ""));

export enum DeployEnvironment {
    Production = "Production",
    Staging = "Staging",
    Development = "Development",
    Local = "Local",
    Test = "Test",
}

export const DEPLOY_ENVIRONMENT = (() => {
    const env = cfg("DEPLOY_ENVIRONMENT", DeployEnvironment.Staging) as DeployEnvironment;
    const valid = Object.values(DeployEnvironment).includes(env);
    if (!valid) {
        debug(
            "Could not determine deploy environment from REACT_APP_DEPLOY_ENVIRONMENT.",
            `Got REACT_APP_DEPLOY_ENVIRONMENT=${env}`,
            `Deploy environment defaulted to "${DeployEnvironment.Staging}".`
        );
        // Default to Staging as it's as restrictive (e.g. hides
        // DevelopmentMenu) as Production but is not actually Production.
        return DeployEnvironment.Staging;
    } else {
        return env;
    }
})();

// Same limit as in backend
// @see DATA_UPLOAD_MAX_MEMORY_SIZE in tmpl-backend/tmpl/settings/base.py
export const MAX_UPLOAD_SIZE = 10485760;
export const MAX_SIGNABLE_DOCUMENT_SIZE_SCRIVE = 10 * 1024 * 1024; // 10mb

export const SUPPORT_EMAIL = "support@hyddawork.com";
