import { snakeCase, camelCase } from "src/lib/transform-case";
import { AbstractFormValue } from "src/models/form-value/abstract-form-value";

type IKeyTransformer = (str: string) => string;
const createKeyTransformator = (fn: IKeyTransformer) => {
    const transform = (obj: ObjectOf<any>): ObjectOf<any> => {
        if (obj instanceof AbstractFormValue) {
            return obj;
        } else if (Array.isArray(obj)) {
            return obj.map(transform);
        } else if (typeof obj === "object" && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                acc[fn(key)] = transform(obj[key]);
                return acc;
            }, {});
        }
        return obj;
    };
    return transform;
};

export const toSnakeCase = createKeyTransformator(snakeCase);
export const toCamelCase = createKeyTransformator(camelCase);

export const formDataToSnakeCase = (data: FormData) => {
    const nextData = new FormData();
    data.forEach((value, key) => {
        nextData.append(snakeCase(key), value);
    });
    return nextData;
};
