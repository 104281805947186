export class RequestError<T> extends Error {
    public readonly response: Response;
    public readonly body: T;

    constructor(body: T, response: Response) {
        super("Request error");
        // To be able to use `obj instanceof RequestError` we have to manually
        // set the prototype with Object.setPrototypeOf(this, RequestError.prototype);
        // for classes inheriting from Error, Map or Array

        // source:
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, RequestError.prototype);
        this.response = response;
        this.body = body;
    }

    public static isBadRequest<T>(request: any): request is RequestError<T> {
        return request instanceof RequestError && request.response.status === 400;
    }

    public static isUnauthorized(request: any): request is RequestError<undefined> {
        return request instanceof RequestError && request.response.status === 401;
    }

    public static isForbidden(request: any): request is RequestError<undefined> {
        return request instanceof RequestError && request.response.status === 403;
    }

    public static isNotFound(request: any): request is RequestError<undefined> {
        return request instanceof RequestError && request.response.status === 404;
    }
}
