import { observable, action } from "mobx";

import { observed } from "src/store/lib/observed";

export interface IAuthToken {
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
}

@observed
export class AuthToken implements Partial<IAuthToken> {
    @observable
    public accessToken?: string;

    @observable
    public expiresIn?: number;

    @observable
    public refreshToken?: string;

    @action
    public set(token: IAuthToken) {
        this.accessToken = token.accessToken;
        this.expiresIn = token.expiresIn;
        this.refreshToken = token.refreshToken;
    }

    @action
    public destroy() {
        this.accessToken = undefined;
        this.expiresIn = undefined;
        this.refreshToken = undefined;
    }

    public isSet() {
        return !!(this.accessToken && this.refreshToken);
    }
}
