import Debug from "debug";

const debug = Debug("tmpl:lib/exhaustive-check");

/**
 * Use to check if a switch statement implements all possible cases.
 */
export function exhaustiveCheck(item: never): void {
    debug(
        `item failed exhaustive check. Got: ${item} (${typeof item}). type should have been 'never'`
    );
}
