import { deepCopyOverride } from "src/lib/object";

export abstract class AbstractFormValue<T extends Readonly<any>> implements IFormValue<T> {
    constructor(public value: string, public attributes: T) {}
    public toJSON() {
        return this.value;
    }

    [deepCopyOverride]() {
        // Since this should be immutable we can reuse the instance.
        // If we make a copy then AbstractForm will fail to detect unchanged
        // form values that is using a FormValue since deepEquals will
        // see different instances.
        return this;
    }
}
